import { CroppableText, DataGridColumns } from '@zaveit/uikit';
import format from '@zaveit/format';
import { useSelector } from 'react-redux';

import { Currencies } from 'constants/index';
import { AccountState, RootState, AuthState } from 'redux/types';
import { formatConsumptionValue } from 'helpers/consumption';
import useStyles from 'components/ConsumptionPage/SubscriptionTable/SubscriptionTable.styles';

export const useSubscriptionTableConfig = (
  consumptions,
): { headCellsSubscription: DataGridColumns; headCellsProduct: DataGridColumns } => {
  const classes = useStyles();
  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const { accountsList } = useSelector((state: RootState): AccountState => state.account);

  const headCellsSubscription: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Subscription',
      hideable: false,
      minWidth: 200,
      flex: 3,
      renderCell: ({ value, row }) => {
        const account = accountsList?.find((item) => item?.id === row?.account);
        return value ? (
          <div className={classes.accountLabelContainer}>
            <CroppableText value={value} />
            {account?.name && (
              <div className={classes.accountLabel}>
                <span>{account?.name}</span>
              </div>
            )}
          </div>
        ) : (
          <>&mdash;</>
        );
      },
    },
    {
      field: 'order_details.price.subtotal',
      headerName: userInfo?.msp ? 'Estimated revenue' : 'Estimated cost',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) => row?.order_details?.price?.subtotal,
      valueFormatter: ({ value }) => value,
      renderCell: ({ value, row }) =>
        value || value === 0 ? (
          format.price(value, Currencies?.[row.currency?.code]).currency
        ) : (
          <>&mdash;</>
        ),
    },
  ];

  const headCellsProduct: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Product',
      hideable: false,
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'consumption',
      headerName: 'Consumption',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ row }) => {
        const name = row?.consumption?.property || '';
        const rowValue = row?.amount;
        const value = formatConsumptionValue(rowValue, name, consumptions);
        return value || <>&mdash;</>;
      },
    },
    {
      field: 'price.subtotal',
      headerName: 'Cost',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) => row?.price?.subtotal,
      valueFormatter: ({ value }) => value,
      renderCell: ({ value, row }) =>
        value || value === 0 ? (
          format.price(value, Currencies?.[row.currency?.code]).currency
        ) : (
          <>&mdash;</>
        ),
    },
  ];

  return { headCellsSubscription, headCellsProduct };
};
