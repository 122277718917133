import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import format from '@zaveit/format';
import { TileData } from 'types/home';
import { Card, CardContent, Typography } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import { TrendingDown, TrendingUp } from '@material-ui/icons';
import { Button, Colors } from '@zaveit/uikit';
import useStyles from './DataTile.styles';
import 'styles/fonts.scss';

interface DataTileProps {
  isLoading: boolean;
  title: string;
  data: TileData | null;
  message: string;
  tooltipLabel: string;
  buttonName: string;
  redirectPath: string;
  isCurrency?: boolean;
}

/**
 * This component contains a chart and needs an object with timeseries data.
 * @param {object} data Object with date[], data[], amount[] to display timeseries data.
 * @param {boolean} isCurrency Select true if the display value should be formatted (ie. 21,000.00).
 * @param {string} tooltipLabel Value displayed on the toolitp in the chart line (ie. "sales").
 */
function DataTile({
  isLoading,
  title,
  data,
  message,
  tooltipLabel,
  buttonName,
  redirectPath,
  isCurrency,
}: DataTileProps) {
  const classes = useStyles();

  const [isTrendNegative, setIsTrendNegative] = useState<boolean>(false);
  const [percentageChange, setPercentageChange] = useState<number | null>(null);

  const todaysValue = data?.data.slice(-1).pop();
  const todaysValueFormatted = isCurrency
    ? format.price(todaysValue).long
    : data?.data.slice(-1).pop();

  // Percentage change calculation
  useEffect(() => {
    if (isLoading) return;

    if (data?.data.length < 2) {
      setPercentageChange(null);
    } else {
      const secondToLastValue = data?.data.slice(-2).shift();
      const lastValue = data?.data.slice(-1).pop();

      if (secondToLastValue === 0) {
        // Special case: when secondToLastValue is 0
        if (lastValue === 0) {
          setPercentageChange(0);
        } else {
          // If secondToLastValue is 0 and lastValue is not 0, consider it a 400% change
          setPercentageChange(100 * lastValue); // Custom logic for percentage change from 0 to non-zero
        }
      } else {
        const change = parseFloat(
          (((lastValue - secondToLastValue) / secondToLastValue) * 100).toFixed(1),
        );

        setIsTrendNegative(change < 0);
        setPercentageChange(change);
      }
    }
  }, [isLoading, data]);

  const chartData = [
    {
      name: tooltipLabel,
      data: data?.data,
      type: 'area',
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000,
        },
      },
    },
    tooltip: {
      hideEmptySeries: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 1,
        opacityFrom: 0.1,
        opacityTo: 0.9,
        stops: [0, 100],
        type: 'vertical',
        inverseColors: false,
      },
    },
    colors: ['#00C9A7'],
    labels: null,
    xaxis: {
      labels: {
        show: false,
      },
      categories: data?.date,
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val.toFixed(0); // Limiting to 2 decimal places
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        width: 0,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
  };

  return (
    <div>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <div className={classes.topSection}>
            <div className={classes.titleTrendRow}>
              <Typography variant="subtitle2">{title}</Typography>
              <div className={classes.trendSection}>
                {isLoading ? (
                  <Skeleton width={50} height={25} />
                ) : percentageChange === 0 ? (
                  <>
                    <>
                      <TrendingUp
                        style={{ color: Colors.grey500, marginRight: '2px' }}
                        fontSize="small"
                      />
                      <Typography variant="caption" style={{ color: Colors.grey500 }}>
                        {percentageChange}%
                      </Typography>
                    </>
                  </>
                ) : (
                  <>
                    {isTrendNegative ? (
                      <>
                        <TrendingDown style={{ color: Colors.red500 }} fontSize="small" />
                        <Typography variant="caption" style={{ color: Colors.red500 }}>
                          {percentageChange}%
                        </Typography>
                      </>
                    ) : (
                      <>
                        <TrendingUp style={{ color: Colors.brand500 }} fontSize="small" />
                        <Typography variant="caption" style={{ color: Colors.brand500 }}>
                          +{percentageChange}%
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              {isLoading ? (
                <Skeleton width={100} height={40} />
              ) : (
                <Typography variant="h4">{todaysValueFormatted}</Typography>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className={classes.chartSkeleton}>
              <Skeleton width="100%" height={110} />
            </div>
          ) : (
            <div className={classes.chartSection}>
              <ReactApexChart series={chartData} height={90} width="100%" options={chartOptions} />
            </div>
          )}
          {isLoading ? (
            <div className={classes.bottomSection}>
              <Skeleton width={75} height={30} />
              <Button styleClass="blue">{buttonName}</Button>
            </div>
          ) : (
            <div className={classes.bottomSection}>
              <Typography variant="caption">
                {data?.amount.length > 0 ? data?.amount.slice(-1).pop() : 0} {message}
              </Typography>
              <Button
                styleClass="blue"
                onClick={() => {
                  window.location.href = `https://${window.location.host}${redirectPath}`;
                }}
              >
                {buttonName}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default DataTile;
