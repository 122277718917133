import { CroppableText, DataGridColumns } from '@zaveit/uikit';
import format from '@zaveit/format';
import { useSelector } from 'react-redux';

import { Currencies } from 'constants/index';
import { formatTitle } from 'helpers/formatHelper';
import { useMemo } from 'react';

export const useCustomerTableConfig = (): {
  headCellsCustomer: DataGridColumns;
  headCellsProduct: DataGridColumns;
  headCellsProductItems: DataGridColumns;
} => {
  // @ts-expect-error
  const { userInfo } = useSelector((state) => state.auth);

  const headCellsCustomer: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Customer',
      hideable: false,
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'orders.price.subtotal',
      headerName: userInfo?.msp ? 'Estimated revenue' : 'Estimated cost',
      type: 'number',
      minWidth: 130,
      flex: 1,
      valueGetter: ({ row }) => {
        const isSimpleCurrency = row?.subscriptions?.length
          ? row?.subscriptions?.every(
              (item) => item?.order_details?.currency?.type === row.orders?.currency?.type,
            )
          : true;
        return isSimpleCurrency ? row.orders?.price?.subtotal : null;
      },
      valueFormatter: ({ value }) => value,
      renderCell: ({ value, row }) =>
        value ? (
          format.price(value, Currencies?.[row.orders?.currency?.type]).currency
        ) : (
          <>&mdash;</>
        ),
    },
  ];

  const headCellsProductItems: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Product',
      hideable: false,
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'consumption.property',
      headerName: 'Consumption property',
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => (row.consumption ? formatTitle(row.consumption.property) : ''),
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'amount',
      headerName: 'Quantity',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 0.5,
      valueFormatter: ({ value }) => value,
      renderCell: ({ value }) => (value || value === 0 ? format.number(value).thousands : <>&mdash;</>),
    },
    {
      field: 'cost',
      headerName: userInfo?.msp ? 'Estimated revenue' : 'Estimated cost',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) => {
        return row?.price?.subtotal;
      },
      valueFormatter: ({ value }) => value,
      renderCell: ({ value, row }) =>
        value || value === 0 ? (
          format.price(value, Currencies?.[row.currency?.code]).currency
        ) : (
          <>&mdash;</>
        ),
    },
  ];

  const headCellsProduct: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Product',
      hideable: false,
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'cost',
      headerName: userInfo?.msp ? 'Estimated revenue' : 'Estimated cost',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      valueGetter: ({ row }) => {
        return row?.price;
      },
      valueFormatter: ({ value }) => value,
      renderCell: ({ value, row }) =>
        value || value === 0 ? (
          format.price(value, Currencies?.[row?.order_details.currency?.type]).currency
        ) : (
          <>&mdash;</>
        ),
    },
  ];

  return useMemo(() => ({ headCellsCustomer, headCellsProduct, headCellsProductItems }), []);
};
